import * as React from "react"
import Layout from "../../../../components/layout"
import { Seo } from "../../../../components/seo"
import { Avatar, Breadcrumb, Card, Space, Tooltip, Typography } from "antd"
import PageContent from "../../../../components/page-content"
import { graphql, Link } from "gatsby"
import { JsonDebugger } from "../../../../components/json-debugger"
import { RichText } from "prismic-reactjs"
import { htmlSerializer } from "../../../../utils/html-serializer"
import { spacer, detailTitle } from "../faq.module.less"
import { ArrowLeftIcon } from "../../../../icons/arrow-left"
import { getShopifyImage } from "gatsby-source-shopify"
import { RelatedTypeQuickNav } from "../../../../components/related-types/related-type.quick-nav"
import { JsonLd } from "react-schemaorg"
import { FAQPage, QAPage } from "schema-dts"
const { Title, Text } = Typography

const FAQDetail = ({ data }) => {
  if (!data) return null

  const faq = { id: data.prismicFaq.id, ...data.prismicFaq.data }
  return (
    <Layout>
      <Seo title={faq.title.text} />
      <JsonLd<QAPage>
        item={{
          "@context": "https://schema.org",
          "@type": "QAPage",
          mainEntity: [
            {
              "@type": "Question",
              name: faq.title.text,
              acceptedAnswer: {
                "@type": "Answer",
                text: RichText.asText(faq.faq_answer.richText),
              },
              sameAs: `${faq.category.document.url}#${faq.uid}`,
              about: {
                "@type": "Thing",
                name: faq.category.document.data.title,
                url: `${faq.category.document.url}`,
              },
            },
          ],
        }}
      />
      <PageContent>
        <Space direction="vertical" size="large" className={spacer}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/learn/">Learn</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/learn/faq/">FAQ</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={faq.category.document.url}>
                {faq.category.document.data.title}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{faq.title.text}</Breadcrumb.Item>
          </Breadcrumb>
          <Card
            title={
              <Space size="large" align="baseline">
                <Link to={faq.category.document.url}>
                  <Text type="secondary">
                    <ArrowLeftIcon />
                  </Text>
                  {/* <Text type="secondary">Back</Text> */}
                </Link>
                <Title className={detailTitle}>{faq.title.text}</Title>
              </Space>
            }
            // extra={
            //   <Link to={faq.category.document.url}>
            //     <Text type="secondary">Back</Text>
            //   </Link>
            // }
          >
            <RichText
              render={faq.faq_answer.richText}
              htmlSerializer={htmlSerializer}
            />
            <RelatedTypeQuickNav related={faq.related} />
          </Card>
          {/* <JsonDebugger json={{ data }} /> */}
        </Space>
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query FAQDetailQuery($id: String!) {
    prismicFaq(id: { eq: $id }) {
      ...RelatedTypeQuickNav
      id
      data {
        faq_answer {
          richText
        }
        title {
          text
        }
        category {
          document {
            ... on PrismicFaqCategory {
              id
              url
              data {
                title
              }
            }
          }
        }
      }
    }
  }
`

export default FAQDetail
